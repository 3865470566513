<template>
  <q-img :src="src">
    <template v-slot:loading>
      <q-spinner-tail color="primary" />
    </template>
  </q-img>
</template>

<script setup>
import { computed } from "vue";
import { urlFor } from "src/boot/sanityUrlFor";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";

defineOptions({ name: "ImageComponent" });

const props = defineProps({
  media: { type: Object, default: () => {} },
  width: { type: Number, default: 1024 },
});

const responsiveWidth = computed(() => useResponsiveImageWidth(props.width));
const placeholderSrc = computed(
  () => props.media.image?.asset?.metadata?.lqip || null
);
const src = computed(() =>
  props.media.image?.asset?.url
    ? urlFor(props.media.image.asset.url)
        .width(Math.round(responsiveWidth.value))
        .url()
    : null
);
</script>
