<template>
  <div class="cursor-pointer flex flex-center fullscreen">
    <div class="atropos my-atropos">
      <div class="atropos-scale">
        <div class="atropos-rotate">
          <div class="atropos-inner">
            <div class="items-center column q-pa-md">
              <img
                alt="Netflix Symbol"
                data-atropos-offset="5"
                src="~assets/netflix-symbol.png"
                style="width: 200px"
              />
              <div
                class="text-center text-h4 text-netflix-bold"
                style="max-width: 500px"
              >
                {{ t("splashScreen.position[0]") }} <br />
                {{ t("splashScreen.position[1]") }}
              </div>
              <div class="q-mt-xl text-center text-h5 text-netflix-light">
                {{ t("splashScreen.name") }}
              </div>
              <div class="q-mt-md" data-atropos-offset="5">
                <q-icon :name="symSharpInput" size="xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, nextTick, onMounted } from "vue";
import { i18n } from "src/boot/i18n";
import { symSharpInput } from "@quasar/extras/material-symbols-sharp";

defineOptions({ name: "SplashScreen" });

const { t } = i18n.global;
const atropos = inject("atropos");

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: ".my-atropos",
  });
});
</script>
