<template>
  <div class="absolute-full relative-position">
    <video
      v-if="src"
      class="absolute-center video-js"
      ref="videoPlayer"
      playsinline
      :type="type"
    ></video>
  </div>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useSoundStore } from "src/stores/sound";
import videojs from "video.js";
import "video.js/dist/video-js.css";

defineOptions({ name: "VideoComponent" });

const emits = defineEmits(["ended", "loaded", "updated"]);

const props = defineProps({
  background: { type: Boolean, default: false },
  fluid: { type: Boolean, default: false },
  loop: { type: Boolean, default: false },
  src: {
    type: String,
    default:
      "https://jpi-sizzle-db-cloudformation-stack-source71e471f1-3k1wpecklum3.s3.amazonaws.com/netflix-new-logo-animation-2019.mp4",
  },
  type: { type: String, default: "video/mp4" },
});

const soundStore = useSoundStore();
const { pauseBackground } = storeToRefs(soundStore);
let player = null;
const videoPlayer = ref(null);

onMounted(async () => {
  await nextTick();
  player = videojs(videoPlayer.value, {
    autoplay: true,
    controls: false,
    fluid: props.fluid,
    loop: props.loop,
    muted: false,
    preload: "auto",
    useDevicePixelRatio: true,
  });
  player.src({ src: props.src });
  player.poster;
  player.load();
  player.on("ended", () => {
    emits("ended", player);
  });
  player.on("loadeddata", () => {
    emits("loaded", player);
  });
  player.on("timeupdate", () => {
    emits("updated", player);
  });
});

watch(pauseBackground, (pause) => {
  if (!props.background) return;
  if (pause) {
    player.pause();
  } else {
    player.play();
  }
});
</script>
