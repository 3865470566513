<template>
  <div class="fit">
    <q-scroll-area class="full-height" visible>
      <div class="relative-position">
        <q-responsive
          :class="{ hidden: !data.soundHasVideo }"
          class="relative-position"
          :ratio="1.77"
        >
          <video-component
            class="absolute-full mask-overflow-b"
            :class="{ invisible: !data.soundHasVideo }"
            fluid
            loop
            :src="data.soundTrack"
            type="application/x-mpegURL"
          />
        </q-responsive>
        <div v-if="!data.soundHasVideo" class="relative-position">
          <q-img
            class="mask-overflow-b"
            :placeholder-src="placeholderSrc"
            :src="src"
          >
            <template v-slot:loading>
              <q-spinner-tail color="primary" />
            </template>
          </q-img>
          <div class="absolute-full flex flex-center">
            <q-img
              v-if="data.logo?.asset?.url"
              class="q-ma-xl"
              :src="
                urlFor(data.logo.asset.url)
                  .width(Math.round(responsiveWidth))
                  .url()
              "
            >
              <template v-slot:loading>
                <q-spinner-tail color="primary" /> </template
            ></q-img>
          </div>
        </div>
        <div class="absolute-top-left q-ma-md">
          <q-img
            alt="Netflix"
            src="~assets/netflix-logo.svg"
            style="width: 100px"
          >
            <template v-slot:loading>
              <q-spinner-tail color="primary" />
            </template>
          </q-img>
        </div>
        <div
          v-if="$q.screen.gt.sm"
          class="absolute-bottom q-ma-xl text-netflix-medium"
        >
          {{ data.role }}
        </div>
      </div>
      <div v-if="!$q.screen.gt.sm" class="q-my-xl text-netflix-medium">
        {{ data.role }}
      </div>
      <div
        v-if="data.logline"
        :class="$q.screen.lt.md ? 'q-mx-lg' : 'q-mx-xl'"
        class="q-mb-xl text-center text-h6 text-netflix-bold text-uppercase"
        style="max-width: 500px"
      >
        {{ data.logline }}
      </div>
      <div :class="$q.screen.lt.md ? 'q-mx-lg' : 'q-mx-xl'" class="q-mb-xl">
        <portable-text
          v-if="data.synopsis"
          :value="data.synopsis"
          :components="customComponents"
        />
      </div>
      <div class="q-mx-xl"><q-separator /></div>
      <div :class="$q.screen.lt.md ? 'q-mx-lg' : 'q-mx-xl'" class="q-my-xl">
        <portable-text
          v-if="data.notes"
          :value="data.notes"
          :components="customComponents"
        />
      </div>
      <div v-if="data.banner?.length">
        <q-img
          v-for="banner in data.banner"
          :key="banner._key"
          :src="
            urlFor(banner.image.asset.url)
              .width(Math.round(responsiveWidth))
              .url()
          "
        >
          <template v-slot:loading>
            <q-spinner-tail color="primary" /> </template
        ></q-img>
      </div>
    </q-scroll-area>
  </div>
</template>

<script setup>
import { computed, h, nextTick, onBeforeUnmount, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { PortableText } from "@portabletext/vue";
import { Dialog, QIcon, useQuasar } from "quasar";
import { urlFor } from "src/boot/sanityUrlFor";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import { useSoundStore } from "src/stores/sound";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "StoryDialog" });

const props = defineProps({
  data: { type: Object, default: () => {} },
  placeholderSrc: { type: String, default: "" },
  src: { type: String, default: "" },
  type: { type: String, default: "story" },
});

const $q = useQuasar();
const soundStore = useSoundStore();
const { pauseBackground } = storeToRefs(soundStore);
const responsiveWidth = computed(() => useResponsiveImageWidth(640));

const customComponents = {
  marks: {
    link: ({ value }, { slots }) => {
      const target = value?.url?.startsWith("http") ? "_blank" : undefined;

      if (value.reference) {
        return h(
          "span",
          {
            class: linkClasses,
            onClick: () => {
              handleClick(value);
            },
          },
          [
            slots.default?.(),
            h(QIcon, {
              name: sharpOpenInBrowser,
              size: "16px",
              class: "q-ml-sm",
            }),
          ]
        );
      } else if (value.url) {
        return h(
          "a",
          {
            href: value.url,
            target,
            class: linkClasses,
            rel: target === "_blank" ? "noopener" : undefined,
          },
          [
            slots.default?.(),
            h(QIcon, { name: sharpOpenInNew, size: "16px", class: "q-ml-sm" }),
          ]
        );
      }
    },
  },
  block: {
    h6: (_, { slots }) => {
      const h6Class = computed(() =>
        $q.screen.lt.md ? "text-body2" : "text-body1"
      );
      const h6Style = computed(() => ($q.screen.lt.md ? 1.5 : 1.4125));
      return h(
        "h6",
        { class: h6Class.value, style: `line-height: ${h6Style.value}` },
        slots.default?.()
      );
    },
  },
};

onBeforeUnmount(() => {
  pauseBackground.value = false;
});

onMounted(async () => {
  await nextTick();
  pauseBackground.value = true;
});
</script>
