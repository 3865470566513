export const heartbeatToggle = {
  heartbeatToggle: {
    src: "https://supernatural-interface.s3.amazonaws.com/heartbeatToggle/heartbeatToggle.mp3",
    sprite: [
      {
        name: "heartbeat_one_toggle",
        start: 0,
        duration: 57.1428571428571,
      },
      {
        name: "heartbeat_three_toggle",
        start: 2000,
        duration: 857.1428571428572,
      },
      {
        name: "heartbeat_two_toggle",
        start: 4000,
        duration: 857.1428571428568,
      },
    ],
  },
};
