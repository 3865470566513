<template>
  <q-scroll-area class="absolute-full" visible>
    <div class="items-center justify-center q-gutter-md row">
      <template v-for="banner in data.banner" :key="banner._key">
        <video-component
          v-if="banner.video"
          class="col col-xs-10 col-md-6 q-mx-auto"
          fluid
          loop
          :src="banner.video"
          type="application/x-mpegURL"
        />
        <template v-else>
          <image-component
            :class="{ 'absolute-center': data.banner.length == 1 }"
            class="col col-xs-10 col-md-6"
            :media="banner"
          />
        </template>
      </template>
    </div>
  </q-scroll-area>
</template>

<script setup>
import { nextTick, onBeforeUnmount, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useSoundStore } from "src/stores/sound";
import ImageComponent from "src/components/image/ImageComponent.vue";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "LetterDialog" });

const props = defineProps({
  data: { type: Object, default: () => {} },
});

const soundStore = useSoundStore();
const { pauseBackground } = storeToRefs(soundStore);

onBeforeUnmount(() => {
  if (props.data.videoHasSound) pauseBackground.value = false;
});

onMounted(async () => {
  await nextTick();
  if (props.data.videoHasSound) {
    pauseBackground.value = true;
  }
});
</script>
