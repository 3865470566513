<template>
  <q-page class="flex flex-center">
    <transition
      appear
      enter-active-class="animated delay-1s fadeIn slower"
      leave-active-class="animated fadeOut"
    >
      <splash-screen
        v-if="mounted && !splashScreenClicked"
        @click="onSplashScreenClick()"
      />
    </transition>
    <video-component
      v-if="splashScreenClicked && !splashScreenEnded"
      class="fixed-center"
      @ended="onSplashScreenEnded()"
    />
    <letter-component v-if="splashScreenEnded" ref="motionRef" />
    <video-component
      v-if="splashScreenEnded"
      background
      class="fixed hidden no-pointer-events"
      loop
      src="https://d2hsqudh7e0r2l.cloudfront.net/out/v1/8863b3e9178542f9b051adfece0d9290/4881d9cacdf84b5c99152ce274631423/49f210203e6b43868c1870b71e4bebc7/index.m3u8"
      type="application/x-mpegURL"
    />
  </q-page>
</template>

<script setup>
import { nextTick, onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useMotion } from "@vueuse/motion";
import { useAppStore } from "src/stores/app";
import LetterComponent from "src/components/letter/LetterComponent.vue";
import SplashScreen from "src/components/splash/SplashScreen.vue";
import VideoComponent from "src/components/video/VideoComponent.vue";

defineOptions({ name: "IndexPage" });

const appStore = useAppStore();
const { app } = storeToRefs(appStore);
const motionRef = ref(null);
const mounted = ref(false);
const splashScreenClicked = ref(false);
const splashScreenEnded = ref(false);
let motionInstance = null;

const applyAnimations = () => {
  motionInstance = createMotionInstance();
  motionInstance.apply("initial");
  nextTick(() => {
    motionInstance.apply("enter");
  });
};

const createMotionInstance = () => {
  return useMotion(motionRef, {
    initial: {
      opacity: 0,
      y: 100,
    },
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 80,
        damping: 20,
        duration: 1000,
        mass: 1,
      },
    },
  });
};

const onSplashScreenClick = () => {
  splashScreenClicked.value = true;
};

const onSplashScreenEnded = () => {
  splashScreenEnded.value = true;
};

onMounted(async () => {
  await nextTick();
  mounted.value = true;
});

watch(splashScreenEnded, async () => {
  await nextTick();
  applyAnimations();
});
</script>
