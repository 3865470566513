<template>
  <div>
    <div class="atropos my-atropos" ref="cover" @click="onClick()">
      <div class="atropos-scale">
        <div class="atropos-rotate">
          <div class="atropos-inner">
            <div class="cursor-pointer relative-position">
              <q-img :placeholder-src="placeholderSrc" :src="src">
                <template v-slot:loading>
                  <q-spinner-tail color="primary" />
                </template>
              </q-img>
              <div class="absolute-full flex flex-center">
                <q-img
                  v-if="story.logo?.asset?.url"
                  class="q-ma-md"
                  data-atropos-offset="3"
                  :src="
                    urlFor(story.logo.asset.url)
                      .width(Math.round(responsiveWidth))
                      .url()
                  "
                >
                  <template v-slot:loading>
                    <q-spinner-tail color="primary" />
                  </template>
                </q-img>
              </div>
              <div class="absolute-top-left">
                <img
                  alt="Netflix Symbol"
                  data-atropos-offset="3"
                  src="~assets/netflix-symbol.png"
                  style="width: 30px"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="q-my-md text-center text-subtitle1">{{ story.title }}</div>
  </div>
</template>

<script setup>
import { computed, inject, nextTick, onMounted, ref } from "vue";
import { Dialog } from "quasar";
import { urlFor } from "src/boot/sanityUrlFor";
import { useResponsiveImageWidth } from "src/composables/image/useResponsiveImageWidth";
import DialogComponent from "src/components/dialog/DialogComponent.vue";

defineOptions({ name: "StoryComponent" });

const props = defineProps({
  story: { type: Object, default: () => {} },
});

const atropos = inject("atropos");
const responsiveWidth = computed(() => useResponsiveImageWidth(640));
const placeholderSrc = computed(
  () => props.story.poster?.asset?.metadata?.lqip || null
);
const src = computed(() =>
  props.story.poster?.asset?.url
    ? urlFor(props.story.poster.asset.url)
        .width(Math.round(responsiveWidth.value))
        .url()
    : null
);
const cover = ref(null);

const onClick = (value) => {
  Dialog.create({
    component: DialogComponent,
    componentProps: {
      data: props.story,
      placeholderSrc: placeholderSrc.value,
      src: src.value,
      type: "story",
    },
  });
};

onMounted(async () => {
  await nextTick();
  const myAtropos = atropos({
    el: cover.value,
  });
});
</script>
