export const clicky = {
  clicky: {
    src: "https://supernatural-interface.s3.amazonaws.com/clicky/clicky.mp3",
    sprite: [
      {
        name: "clicky_alt",
        start: 0,
        duration: 2000,
      },
      {
        name: "clicky_low",
        start: 3000,
        duration: 2000,
      },
      {
        name: "clicky",
        start: 6000,
        duration: 2000,
      },
    ],
  },
};
