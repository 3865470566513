import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { Howl } from "howler";
import { sprites } from "src/sound/sprites";

export const useSoundStore = defineStore("sound", () => {
  const currentIndexes = ref({});
  const pauseBackground = ref(false);
  const sounds = ref({});
  const soundIds = ref({});
  let sound;

  const playSound = (group, next = true) => {
    const spriteData = sprites[group];
    if (spriteData) {
      const { src, sprite } = spriteData;
      const spriteObj = sprite.reduce((acc, { name, start, duration }) => {
        acc[name] = [start, duration];
        return acc;
      }, {});
      if (typeof currentIndexes.value[group] === "undefined") {
        currentIndexes.value[group] = 0;
      }
      if (next) {
        currentIndexes.value[group] =
          (currentIndexes.value[group] + 1) % sprite.length;
      } else {
        currentIndexes.value[group] =
          (currentIndexes.value[group] - 1 + sprite.length) % sprite.length;
      }
      const spriteName = sprite[currentIndexes.value[group]].name;
      const loop = sprite[currentIndexes.value[group]].loop || false;
      sound = new Howl({
        src: [src],
        sprite: spriteObj,
        loop,
        volume: 0,
      });
      sounds.value[group] = sound;
      const soundId = sound.play(spriteName);
      sound.fade(0, 1, 60, soundId);
      soundIds.value[group] = soundId;
    }
  };

  const preloadSound = (group) => {
    const spriteData = sprites[group];
    if (spriteData) {
      const { src } = spriteData;
      const audio = new Audio(src);
      audio.preload = "auto";
    }
  };

  const stopSound = (group) => {
    if (sounds.value[group]) {
      const soundId = soundIds.value[group];
      if (soundId) {
        sounds.value[group].fade(1, 0, 1000, soundId);
        sounds.value[group].once(
          "fade",
          () => {
            sounds.value[group].stop(soundId);
          },
          soundId
        );
      }
    }
  };

  return {
    pauseBackground,
    playSound,
    preloadSound,
    stopSound,
  };
});
