<template>
  <q-dialog maximized ref="dialogRef" @hide="onDialogHide">
    <q-card
      class="fullscreen transparent"
      :dark="false"
      square
      @click="onOKClick()"
    >
      <component
        :data="data"
        :is="isComponent(type)"
        :placeholder-src="placeholderSrc"
        :src="src"
      />
      <div class="absolute-top-right q-mr-md q-mt-sm" style="z-index: 1">
        <div class="backdrop-blur backdrop-dimmed">
          <q-btn
            dense
            :icon="symSharpClose"
            :size="$q.screen.gt.sm ? 'md' : 'sm'"
            square
            unelevated
            @click="onOKClick()"
          />
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { symSharpClose } from "@quasar/extras/material-symbols-sharp";
import LetterDialog from "src/components/letter/LetterDialog.vue";
import StoryDialog from "src/components/story/StoryDialog.vue";

const props = defineProps({
  data: { type: Object, default: () => {} },
  placeholderSrc: { type: String, default: "" },
  src: { type: String, default: "" },
  type: { type: String, default: "swiper" },
});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const isComponent = (type) => {
  switch (type) {
    case "letter":
      return LetterDialog;
    default:
      return StoryDialog;
  }
};

function onOKClick() {
  onDialogOK();
}
</script>
