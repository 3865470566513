<template>
  <div class="fullscreen relative-position">
    <q-scroll-area class="absolute-full" :thumb-style="thumbStyle" visible>
      <div
        class="q-mx-auto q-pt-xl q-px-xl text-grey text-netflix-light"
        style="max-width: 920px"
      >
        <img
          alt="Netflix"
          src="~assets/netflix-logo.svg"
          style="width: 200px"
        />
        <div class="text-grey">
          <template v-for="content in app.netflix.content" :key="content._key">
            <portable-text
              v-if="content._type === 'bodyBlock'"
              :value="content.body"
              :components="customComponents"
            />
            <div
              class="full-width items-center justify-center q-col-gutter-md q-py-xl q-mb-xl row"
              v-else
            >
              <story-component
                v-for="story in content.stories"
                class="col-xs-10 col-sm-6 col-md-4 col-lx-3"
                :key="story._key"
                :story="story"
              />
            </div>
          </template>
        </div>
        <div>
          <img src="~assets/cq.png" style="width: 200px" />
        </div>
      </div>
    </q-scroll-area>
    <div class="fade-effect" />
  </div>
</template>

<script setup>
import { h, computed } from "vue";
import { storeToRefs } from "pinia";
import { Dialog, QIcon, useQuasar } from "quasar";
import {
  sharpOpenInNew,
  sharpOpenInBrowser,
} from "@quasar/extras/material-icons-sharp";
import { useAppStore } from "src/stores/app";
import { PortableText } from "@portabletext/vue";
import DialogLetterComponent from "src/components/dialog/DialogLetterComponent.vue";
import StoryComponent from "src/components/story/StoryComponent.vue";

defineOptions({ name: "LetterComponent" });

const $q = useQuasar();
const appStore = useAppStore();
const { app } = storeToRefs(appStore);

const linkClasses = "custom-link q-pt-none";
const thumbStyle = {
  borderRadius: "0px",
};

const handleClick = (value) => {
  Dialog.create({
    component: DialogLetterComponent,
    componentProps: {
      type: "letter",
      data: value,
    },
  });
};

const customComponents = {
  marks: {
    link: ({ value }, { slots }) => {
      const target = value?.url?.startsWith("http") ? "_blank" : undefined;

      if (value.banner?.length) {
        return h(
          "span",
          {
            class: linkClasses,
            onClick: () => {
              handleClick(value);
            },
          },
          [
            slots.default?.(),
            h(QIcon, {
              name: sharpOpenInBrowser,
              size: "16px",
            }),
          ]
        );
      } else if (value.url) {
        return h(
          "a",
          {
            href: value.url,
            target,
            class: linkClasses,
            rel: target === "_blank" ? "noopener" : undefined,
          },
          [slots.default?.(), h(QIcon, { name: sharpOpenInNew, size: "16px" })]
        );
      }
    },
  },
  block: {
    h4: (_, { slots }) => {
      const h4Class = computed(() =>
        $q.screen.lt.md ? "text-body1" : "text-h5"
      );
      const h4Style = computed(() => ($q.screen.lt.md ? 1.5 : 1.4125));
      return h(
        "h4",
        { class: h4Class.value, style: `line-height: ${h4Style.value}` },
        slots.default?.()
      );
    },
  },
};
</script>

<style>
.custom-link {
  background-color: var(--q-dark);
  color: white;
  text-decoration: underline;
  transition: background-color 0.3s, color 0.3s;
}

.custom-link:hover {
  background-color: var(--q-primary);
  color: white;
  cursor: pointer;
}

.custom-link.visited {
  background-color: var(--q-grey);
  color: var(--q-dark);
}
</style>
